
import * as actionTypes from "../actions/actionTypes";
const initialState = {
  isAuthenticated: false,
}

const loginSuccess = (state) => {
  return {
    ...state,
    isAuthenticated: true
  }
}

const logoutSuccess = (state) => {
  return {
    ...state,
    isAuthenticated: false
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state);
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state);
    default:
      return state;
  }
};

export default reducer;
