import {UPDATE_INGEST_LOCATIONS} from "../actions/actionTypes"; 


const initialState = [];

const update = (locations) => {
  return [...locations]; 
}

export default (state = initialState, action) => {
  switch (action.type){
    case UPDATE_INGEST_LOCATIONS:
      //pass just the array of locations in 
      return update(action.locations); 
    default:
      return state;
  }
}