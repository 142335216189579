import React from "react"; 
import {ThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";
import {LOGIN_BACKGROUND_COLOR} from "../../config/config";

const loginTheme = createMuiTheme({
    palette : {
        background: {
            default: LOGIN_BACKGROUND_COLOR,
        }
    },
    shape : {
        borderRadius : 8
    }
})


export default props => {
    return(
        <ThemeProvider theme={loginTheme}>
             <CssBaseline />
            {props.children}
        </ThemeProvider>
    )
}