import * as actionTypes from "../actions/actionTypes";


const initialState = {message : "" , variant : "", id : -1};

const newNotification = (state, action) => {
  return {message : action.message, variant : action.variant, id : state.id + 1}
}


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION:
      return newNotification(state, action);
    default:
      return state;
  }
};


export default reducer;
