import React from "react";

import {
  AppBar,
  Toolbar,
  Slide,
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  popupBar:{
    top : "auto",
    bottom : 0,
  } ,
}));


 export default props => {
  const classes = useStyles();
  return (
    <Slide
      in={props.on}
      direction="up"
      mountOnEnter={true}
      unmountOnExit={true}
    >
          <AppBar
            color="default"
            position="fixed"
            className={classes.popupBar}
            style={{ background: 'transparent', boxShadow: 'none'}}
          >
            <Toolbar>
              {props.children}
            </Toolbar>
          </AppBar>
    </Slide>
  );
};
