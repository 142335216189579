import axios from "axios"; 
import { actionOK, actionError} from "./utils";

import * as api from "./api";
import * as actionTypes from "./actionTypes";


export const fetchNotes = () => async dispatch => {
  try {
    const result = await axios.get(api.notes);
    const notes = result.data.notes;
    dispatch(updateNotes(notes)); 
  } catch (err) {
    actionError(dispatch, err, "Could not fetch notes");
  }
}

export const saveNote = note => async dispatch => {
  try {
    await axios.post(api.notes,{content : note.content}); 
    dispatch(fetchNotes());
    actionOK(dispatch, "Note Saved"); 
  } catch (err) {
    actionError(dispatch, err, "Could not save new note"); 
  }
}

export const modifyNote = note => async dispatch => {

  try {
    const route = `${api.notes}/${note.id}/contents`
    await axios.put(route, {content : note.content});
    dispatch(fetchNotes());
    actionOK(dispatch, "Note Modified");
  } catch (err) {
    actionError(dispatch, err, "Could not save changes to note"); 
  }
}

export const deleteNote = note => async dispatch => {
  try {
    const route = `${api.notes}/${note.id}`;
    await axios.delete(route);
    dispatch(fetchNotes());
    actionOK(dispatch, "Note Deleted"); 
  } catch (err) {
    actionError(dispatch, err, "could not delete note"); 
  }
}

const updateNotes = (noteList) => {
  return {type: actionTypes.UPDATE_NOTES, notes: noteList};
}
