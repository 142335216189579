import React from "react";

import {
  Dialog,
  DialogContent,
  InputBase,
  Button,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import {
  Clear
} from "@material-ui/icons";

import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  textBox : {
    width : "100%",
  }
}));


export default props => {
  const {note} = props;
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      scroll="body"
      maxWidth="sm"
      fullWidth
    >
      <DialogActions>
        <IconButton onClick={props.onClose}>
          <Clear fontSize="small"/>
        </IconButton>
      </DialogActions>

      <DialogContent >
        <InputBase
          className={classes.textBox}
          placeholder={"Write Something..."}
          autoFocus={true}
          label="Note"
          multiline
          rows={8}
          value={note.content}
          variant="outlined"
          onChange={props.onChange}
        />
       </DialogContent>

       <DialogActions>
         <Button variant="text" onClick={props.delete}>
           Delete
         </Button>
         <Button  variant="text"  onClick={props.save} color="primary">
           Save
         </Button>
       </DialogActions>
    </Dialog>
  );
}
