import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App.js';
import {createStore, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Login from "./containers/Login/Login.js";
import Signup from "./containers/Signup/Signup.js";
import thunk from "redux-thunk"
import 'typeface-roboto'
import {testSession} from "./store/actions/index.js"
import reducer from "./store/reducers/rootReducer.js";
import Theme from "./Components/Themes/AppTheme";
import Helmet from "react-helmet"; 
import {unregister} from "./registerServiceWorker.js"; 
import {SERVER_NAME} from "./config/config"; 

import NotistackProvider from "./Components/NotistackProvider/NotistackProvider";
const store = createStore(reducer, applyMiddleware(thunk));
store.dispatch(testSession());

const content = (
  <div>
    <Helmet>
      <title>
        {SERVER_NAME}
      </title>
    </Helmet>
    <Provider store={store}>
      <Theme>
        <NotistackProvider>
          <BrowserRouter>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route exact path="/" component={App} />
            </Switch>
          </BrowserRouter>
        </NotistackProvider>
      </Theme>
    </Provider>
  </div>
);

ReactDOM.render(content, document.getElementById('root'));
unregister();