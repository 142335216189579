import React from "react";
import {Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    width : "100%",
    padding: theme.spacing(1)
  },
}));


export default props => {

  const {timeString = "00:00:00"} = props;
  const classes = useStyles();
  
  return (
    <Grid container alignItems="center" spacing={1} className={classes.root}>
      <Grid item xs={4}>
        <Typography variant="h6">
          Uptime
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" align="right">
          {timeString}
        </Typography>
      </Grid>
    </Grid>
  );
}
