import React from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles"
import PieGraph from "./PieGraph"; 
import { toDataContext } from "./units.js";

import {
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core/";


const useStyles = makeStyles(theme =>({
  root: {
    padding : theme.spacing(2)
  },
  graphContainer: {
    width: "100%",
    padding: theme.spacing(1)
  },
  headerLeft: {
    display : "flex", 
    alignItems: "center",
  },
  headerRight: {
    display : "flex", 
    alignItems: "center",
    justifyContent: "flex-end",
  }
}));


export default props => {

  //if there is no drive data return null
  if (!props.drive) return null;  
  
  const {used, total , name, color} = props.drive; 

  const handleClose = props.onClose;
  
  const classes = useStyles(); 
  const theme = useTheme(); 


  const usedSection = ({
    name: "Used",
    used: used,
    color : color,
    caption : toDataContext("B", used),
  })

  const freeSection = ({
    name : "Free Space",
    used : total - used,
    caption : toDataContext("B", total - used),
    color :  theme.graphDefaultColors.grey,
  })

  const pieData =[usedSection, freeSection]; 

  const percentage = Math.round((used/total)*100);

  const Header = (
    <Grid item xs={12} container>
      <Grid item xs={4} className={classes.headerLeft}>
        <Typography variant="h6">
          {name}
        </Typography>      
      </Grid>
      <Grid item xs={8} className={classes.headerRight}>
        <Typography variant="h6">
          {`${percentage}%`}
        </Typography>
      </Grid>
    </Grid>

  );

  const Graph = (
    <Grid item xs={12} className={classes.graphContainer}>
      <PieGraph data={pieData}/>
    </Grid>
  );

  return (
    <Dialog
      open={props.drive !== null}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <Grid container spacing={1} direction="column" className={classes.root}>
        {Header}
        {Graph}
      </Grid>
    </Dialog>
  );
}
