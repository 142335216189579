
import React, { useState, Fragment } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector
} from "recharts";
import { useTheme } from "@material-ui/core/styles";


export default (props) => {
  const data = props.data;
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (data, index) => setActiveIndex(index);

  const determineColor = (index) => {
    const defaultColors = theme.graphDefaultColors.primary;
    //Use color data provided via props. If not use colors defined under theme
    //use grey as a global default
    if (data[index].color) return data[index].color;
    else if (defaultColors[index]) return defaultColors[index];
    else return theme.graphDefaultColors.grey;
  }

  const PieCenter = (props) => {
    const {cx, cy, title, titleColor, caption, captionColor} = props;
    const titleText = (
      <text fontSize={14} x={cx} y={cy} dy={4} textAnchor="middle" fill={titleColor}>
        {title}
      </text>
    );


    const captionText = (caption && captionColor)? (
      <text fontSize={12} x={cx} y={cy} dy={16} textAnchor="middle" fill={captionColor}>
        {caption}
      </text>
    ) : null; 

    return(
      <Fragment>
        {titleText}
        {captionText}
      </Fragment>
    );
  }


  const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle,
      fill, payload } = props;

      const caption = data[activeIndex].caption ? data[activeIndex].caption : null;


    return (
      <g>
        <PieCenter
          cx={cx}
          cy={cy}
          title={payload.name}
          titleColor={fill}
          caption={caption}
          captionColor={theme.palette.text.primary}
        />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const optSectionClick = props.pieSectionClick? props.pieSectionClick : ()=>{}; 


  return (
    <ResponsiveContainer width="100%" height={190}>  
      <PieChart>
        <Pie
          startAngle={90}
          endAngle={-270}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          onMouseEnter={onPieEnter}
          nameKey="name"
          dataKey="used"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={65}
          outerRadius={85}
          isAnimationActive={false}
          onClick={optSectionClick}
        >
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={determineColor(index)}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );

}