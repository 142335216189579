import * as actionTypes from "../actions/actionTypes.js"
import {isEqual} from "lodash";
const initialState = []


const saveServerMessages = (state, action) => {
  //If the data is the same no need to force re renders
  if (isEqual(state, action.messages)) return state;

  return action.messages.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

}


/**
 * Reducer for updating Messages in notification center
 * @param  {[Object]} [state=initialState] The current state of message List
 * @param  {Object} action                  The action to modify the state
 *
 * @param  {String} action.type             The String to trigger reducer
 *
 * @param  {[Object]} action.messages       The payload of the current messages
 * @param  {String}   action.messages[].timestamp The timestamp of the message
 * @param  {String}   action.messages[].message   The message text
 * @return {[Object]} The new state
 */
const reducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.RECIEVE_MESSAGES:
      return saveServerMessages(state, action)
    default :
      return state;
  }
}

export default reducer;
