import axios from "axios";
import {actionError, actionOK} from "./utils"; 

import * as actionTypes from "./actionTypes"; 


import * as api from "./api";
const {join} = api;

import {updateDownloadStatus} from "./downloadMonitor";



export const fetchShows = () => async dispatch => {
  try {
    await axios.put(api.mediaFetch);
    actionOK(dispatch, "Server Fetch Completed");
  } catch (err) {
    actionError(dispatch, err, "Could not fetch for new media");
  }
}

export const downloadLink = (link, destinationLibrary = "") => async dispatch => {
  //if no libraries are enabled by the server, then it would be ""
  if (destinationLibrary === "") {
    return actionError(dispatch, new Error("No Destination set"), "No destination set");
  }
  const route = join(api.mediaIngest, destinationLibrary);
  try {
    await axios.put(route, {link : link});
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "Download Started"); 
  } catch (err) {
    actionError(dispatch, err, "Invalid Link"); 
  }
}

export const getIngestLocations = () => async dispatch => {
  try {
    const response = await axios.get(api.mediaIngest);
    dispatch(saveIngestLocations(response.data)); 
  } catch (err) {
    actionError(dispatch, err, "Could not retrieve media ingest locations");
  }
}

export const pauseDownload = id => async dispatch => {
  try {
    const route = join(api.activeDownloads, String(id), "pause");
    await axios.put(route);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "Paused");
  } catch (err) {
    actionError(dispatch, err, "Could not pause download");
  }
}

export const resumeDownload = id => async dispatch => {
  try {
    const route = join(api.activeDownloads, String(id), "resume");
    await axios.put(route);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "Resumed");
  } catch (err) {
    actionError(dispatch, err, "Could not resume download");
  }
}
export const cancelDownload = id => async dispatch => {
  try {
    const route = join(api.activeDownloads, String(id));
    await axios.delete(route);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "Cancelled");
  } catch (err) {
    actionError(dispatch, err, "Could not cencel download"); 
  }
}

export const pauseAllDownloads = () => async dispatch => {
  try {
    const route = join(api.activeDownloads, "pause"); 
    await axios.put(route);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "paused all downloads"); 
  } catch (err) {
    actionError(dispatch, err, "Could not pause all downloads"); 
  }
}
export const resumeAllDownloads = () => async dispatch => {
  try {
    const route = join(api.activeDownloads, "resume");
    await axios.put(route);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "resumed all downloads"); 
  } catch (err) {
    actionError(dispatch, err, "Could not resume all downloads"); 
  }
}
export const cancelAllDownloads = () => async dispatch => {
  try {
    await axios.delete(api.activeDownloads);
    dispatch(updateDownloadStatus());
    actionOK(dispatch, "cancelled all downloads"); 
  } catch (err) {
    actionError(dispatch, err, "Could not cancel all downloads"); 
  }
}


const saveIngestLocations = (locations) => {
  return {type: actionTypes.UPDATE_INGEST_LOCATIONS, locations : locations};
}

