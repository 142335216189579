import React ,{ Fragment, useState }from "react";
import path from "path";
import { makeStyles, useTheme } from "@material-ui/core/styles/";

import {Typography, Grid} from "@material-ui/core/";
import PieGraph from "./PieGraph";
import {toDataContext} from "./units.js";

import DriveDisplay from "./DriveDisplay";


const useStyles = makeStyles(theme => ({
  root: {
    width : "100%",
    padding: theme.spacing(1),
  }
}));



export default props => {
  const ROOT_ALIASES = ["/", "/Host_Drives"]; 
  const classes = useStyles();
  const theme = useTheme();
  const graphColors = theme.graphDefaultColors.primary; 

  //states
  const [selectedDrive, setSelectedDrive] = useState(null);

  //sort algorithm to array drives by largest capacity
  const byLargestCapacity = (driveA, driveB) => (
    driveA.total < driveB.total ? -1 : 1
  );
  
  //handler to display a detailed vieww of the drive by setting the state to the selected drive
  const displayDrive = (data, index) => {
    const drive = pieChartData[index]; 
    //catches the free space section of the pie chart, where there really is no data
    if (!(drive.used) || !(drive.total)) return;
    //set the active drive to the right object
    setSelectedDrive(drive); 
  }
  const handleDialogClose = () => {
    setSelectedDrive(null);
  }

  //Calculated values
  const totalUsed = props.storage.reduce((acc, drive) => acc + drive.used, 0);
  const totalSpace = props.storage.reduce((acc, drive) => acc + drive.total, 0);
  const percentUsed = Math.round((totalUsed / totalSpace) * 100);

  //used for total used and capacity captions in header
  const totalUsedCaption = toDataContext("B", totalUsed); 
  const totalCapacityCaption = toDataContext("B", totalSpace); 

  const pieChartData = formatDriveData(props.storage.sort(byLargestCapacity));

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        className={classes.root}
        direction="column"
      >
        {/* Header of the component, with name, usage and capacity */}
        <Grid item container>
          <Grid item xs={10}>
            <Typography variant="h6">
              Storage
            </Typography>
          </Grid>
          {/* The percent utilization with capacity caption */}
          <Grid item container xs={2} direction="column">
            <Grid item container justify="flex-end">
              <Typography variant="h6" align="right">
                {`${percentUsed}%`}
              </Typography>
            </Grid>
            
            <Grid item container justify="flex-end">
              <Typography variant="caption">
                {`${totalUsedCaption}/${totalCapacityCaption}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Main body of component holding the graph */}
        <Grid item xs={12}>
          {/* the pie chart area */}
          <PieGraph data={pieChartData} pieSectionClick={displayDrive}/>
        </Grid>
      </Grid>
      {/* Clicking modal to display detailed information about drive */}
      <DriveDisplay
        onClose={handleDialogClose}
        drive={selectedDrive}
      />
    </Fragment>
  );



  //Helpers
  //method to format the drive data for the pie chart
  function formatDriveData(data){
    //calculate the amount of free space in each drive and reduce it to a number
    const freeSpace = data.reduce((acc, e) => acc + (e.total - e.used), 0);
    //add in some additional information so charting components can read
    let formattedData = data.map((entry, index) => {
      //format the data caption displaying data used and drive capavity
      const spaceUsedCaption = toDataContext(entry.unit, entry.used); 
      const spaceTotalCaption = toDataContext(entry.unit, entry.total);
      return ({
        //if a root drive path is detected "/" or "Host_drives" replace with with the "Root" name for easier reading
        name: ROOT_ALIASES.includes(entry.name) ? "Root" : path.basename(entry.name),
        used: entry.used,
        total : entry.total, 
        color : graphColors[index],
        caption: `${spaceUsedCaption}/${spaceTotalCaption}`
      });
    });
  
    //create an entry for free space
    const freeSpaceCaption = toDataContext("B", freeSpace);
    formattedData.push({
      name: "Total Free Space",
      used: freeSpace,
      color: theme.graphDefaultColors.grey,
      caption : `${freeSpaceCaption} Free`
  
    });
    return formattedData;
  };  
}
