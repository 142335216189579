import React from "react";
import {withTheme} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";

const center = (props) => {

  const spacing = props.theme.spacing(2)  ;

  var rootStyle = {
  }

  const addPadding = area => {
    if (area === "top") rootStyle["paddingTop"] = spacing;
    if (area === "bottom") rootStyle["paddingBottom"] = spacing;
    if (area === "left") rootStyle["paddingLeft"] = spacing;
    if (area === "right") rootStyle["paddingRight"] = spacing;
  }

//padding is an array of everything you want to be padded
if (props.padding){
  if (!Array.isArray(props.padding)){
    console.log("adding Padding too all sides");
    ["top","bottom","left","right"].forEach(addPadding);
  }
  else {
    props.padding.forEach(side => addPadding(side) )
  }
}


var size = props.size ? props.size : "md"


  return (
    <Container maxWidth={size} style={rootStyle}>
      {props.children}
    </Container>
  );
}
export default withTheme(center);
