import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import {
  Paper,
  List,
  Typography,
  ListItem,
  Grid,
} from "@material-ui/core/";

import Download from "./DownloadEntry.js"
import NetworkViewer from "../../Components/SystemMonitor/NetworkViewer";
import IngestSource from "./Ingest"; 

const useStyles = makeStyles(theme => ({
  menu: {
    paddingLeft : theme.spacing(1),
    paddingRight : theme.spacing(1),
    paddingTop : 0,
    paddingBottom : 0,
    height : 40,
  },
  downloadTitle : {
    paddingLeft : theme.spacing(4),
    paddingTop : theme.spacing(2),
  },
}));


export default props => {
  const classes = useStyles();
  const downloads = useSelector(state => state.status.downloads);
  const networkInfo = useSelector(state => state.status.status.network);
  
  const downloadGraphs = (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <ListItem>
          <NetworkViewer network={networkInfo}/>
        </ListItem>
      </Grid>
    </Grid>
  );
  const downloadStatus = (
    <Grid item  xs={12}>
        <Typography variant="h6" className={classes.downloadTitle}>
          Active Downloads:
        </Typography>
        <List>
          {downloads.map((e , index) =>
            <Download
              key={index}
              id={e.id}
              name={e.name === e.hashString ? "Retrieving Metadata" : e.name }
              percentage={e.percentage}
              status={e.statusMsg}
            />
          )}
        </List>
    </Grid>
  );

  return (
    <Grid
      container
      spacing={0}
      direction="column"
    >
      <Grid item xs={12} className={classes.magnetLinkBar}>
        <IngestSource/>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={1} direction="column">
            {downloadGraphs}
            {downloadStatus}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
