import {notification} from "./notification";


export const copyToClipboard = (text) => (dispatch) => {
    if (typeof text === "string" || text.length > 0){
        navigator.clipboard.writeText(text); 
        actionOK(dispatch, "Copied to Clipboard");
    }
}

/**
 * 
 * @param {Error} error The error the action encountered
 * @param {function} dispatch the dispatch funciton to notify the user
 * @param {String} message the message to notify the user with
 * @param {Object} options additional options to control what to output
 * Defaults to notifying the user via toast but not outputting to console
 */
export const actionError = (dispatch, error, message, options = {notifyUser : true, logError : false}) => {
    if (options && options.notifyUser) dispatch(notification(message, "error"));
    if (options && options.logError) console.log(error);
}


/**
 * 
 * @param {function} dispatch the dispatch funciton to notify the user
 * @param {String} message the message to notify the user with
 */
export const actionOK = (dispatch, message) => {
    dispatch(notification(message, "success")); 
}