import axios from "axios";
import {actionError} from "./utils";

import * as api from "./api";
import * as actionTypes from "./actionTypes";
const {join} = api; 


export const updateNC = () => async dispatch => {
    try {
        const result = await axios.get(api.notificationCenter);
        const notifications = result.data;
        dispatch(saveToNC(notifications)); 
    } catch (err) {
        actionError(dispatch, err, "Could not fetch notifications"); 
    }
}

export const pingNC = () => async dispatch => { 
    try {
        const route = join(api.notificationCenter, "pull");
        const result = await axios.get(route);
        const shouldUpdate = result.data.isNewNotification; 
        if (shouldUpdate) dispatch(updateNC());
    } catch (err) {
    }
}


export const deleteNCMessage = id => async dispatch => {
    try {
        const route = join(api.notificationCenter, String(id));
        await axios.delete(route);
        dispatch(updateNC());
    } catch (err) {
        actionError(dispatch, err, "Could not clear notification"); 
    }
}


export const deleteAllNCMessage = () => async dispatch => {
    try {
        await axios.delete(api.notificationCenter); 
        dispatch(updateNC()); 
    } catch (err) {
        actionError(dispatch, err, "Could not clear all notifications")
        
    }
}


const saveToNC = (messages) => {
    //return {type : actionTypes.RECIEVE_MESSAGES, messages: messages}
    return { ...messages, type: actionTypes.RECIEVE_MESSAGES };
}
