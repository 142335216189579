import * as actionTypes from "./actionTypes";
import * as api from "./api";

import axios from "axios";
import {actionError} from "./utils";



export const fetchPreferences = () => async dispatch => {
  try {
    const response = await axios.get(api.preferences);
    const preferences = response.data;
    applyPreferences(dispatch, preferences);  
  } catch (err) {
    actionError(dispatch, err, "Could not fetch preferences for user"); 
  }
}

export const setPreferences = prefs => async dispatch => {
  try {
    await axios.post(api.preferences, {prefs: prefs});
    dispatch(fetchPreferences());
  } catch (err) {
    actionError(dispatch, err, "could not save preferences"); 
  }
}

function applyPreferences(dispatch, prefs){
  if (prefs.darkTheme !== undefined) dispatch(setDarkTheme(prefs.darkTheme));
}

export const setDarkTheme = (value) => {
  return {type : actionTypes.SET_DARK_THEME, useDarkTheme: value};
}

