import {
    UPDATE_ONLINE_LIST,
    UPDATE_LIBRARY_LIST,
    UPDATE_USER_LIST,
    UPDATE_BACKLOG_LIST,
    DISPLAY_SERVER_INFO,
    CLEAR_SERVER_INFO
}
    from "../actions/actionTypes";

import _ from "lodash"; 
const initialState = {
    lists : [
        {
          viewName: "watchlist",
          contents: []
        }, {
          viewName: "library",
          contents: []
        }, {
          viewName: "online",
          contents: []
        }, {
          viewName : "backlog",
          contents: []
        }
    ],
    selectedShow : null,
};

const updateList = (index, state,list) => {
    let newState = _.cloneDeep(state); 
    newState.lists[index].contents = list;
    return newState;
}

const updateSelectedShow = (state, showName) => {
    let newState = {...state, selectedShow : showName }
    return newState;
} 


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_LIST:
            return updateList(0,state, action.list)
        case UPDATE_LIBRARY_LIST:
            return updateList(1, state, action.list)
        case UPDATE_ONLINE_LIST:
            return updateList(2, state, action.list)
        case UPDATE_BACKLOG_LIST:
            return updateList(3, state, action.list)
        case DISPLAY_SERVER_INFO:
            return updateSelectedShow(state, action.showName)
        case CLEAR_SERVER_INFO:
            return updateSelectedShow(state, null); 
        default:
            return state; 
    }
}

export default reducer; 