import React , {useState, useEffect, useMemo, useCallback} from "react";

import {
  Tabs,
  Tab,
  Paper,
  Grid,
  AppBar,
  Toolbar,
  List,
  Fade,
  TablePagination,
} from "@material-ui/core/";

import {makeStyles} from "@material-ui/core/styles"
import Show from "./Show"

import {useDispatch, useSelector}  from "react-redux";
import {fetchSources, fetchOnlineList, fetchLibList, fetchUserList, fetchBacklogList} from "../../store/actions/index.js"

import ActionBar from "./ActionBar";

import Searchbar from "../../Components/SearchBar/Searchbar";

import {searchByKey, capitalize} from "../../utils/util";

import ShowInfoViewer from "./ShowInfoViewer"; 

const useStyles = makeStyles(theme => ({
  actionBar : {
    paddingTop : theme.spacing(1),
  },
  searchBar :  {
    borderRadius: theme.shape.borderRadius,
  },
  roundTop: {
    borderTopLeftRadius : theme.shape.borderRadius,
    borderTopRightRadius : theme.shape.borderRadius,
  },
  roundBottom: {
    borderBottomLeftRadius : theme.shape.borderRadius,
    borderBottomRightRadius : theme.shape.borderRadius,
  },
  round : {
    borderRadius : theme.shape.borderRadius,
  },
}));

const ITEMS_PER_PAGE_OPTIONS = [5,10,25, 50];


const paginate = (list, pageNumber, itemsPerPage) => {
    return list.slice(pageNumber * itemsPerPage , (pageNumber + 1) * itemsPerPage);
}

export default  props => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const showLists  = useSelector(state => state.shows).lists;
  const availableRSSSources = useSelector(state => state.RSS).sources; 

  const [checkedItems, setCheckedItems] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage , setItemsPerPage] = useState(ITEMS_PER_PAGE_OPTIONS[3]);

  const [searchSource, setSearchSource] = useState("");

  useEffect(() => {
    dispatch(fetchLibList());
    dispatch(fetchUserList());
    dispatch(fetchBacklogList());
    dispatch(fetchSources()); 
  }, [dispatch]);

  //defaults to the first source to display
  useEffect(()=> {
    //set searchSelect to the first available source if prevsiously not set and
    //ONLY when the search source options arrive
    if(searchSource === "" && availableRSSSources.length > 0) setSearchSource(availableRSSSources[0]);
  }, [searchSource, availableRSSSources, setSearchSource])
  
  

  //will run if the source is changed
  useEffect(() => {
    if (searchSource !== "") dispatch(fetchOnlineList(searchSource));
  }, [searchSource, dispatch])



  const bindHandleCheckbox = key => () => {
    setCheckedItems(prevItems => {
      const currentIndex = prevItems.indexOf(key);
      const newItems = [...prevItems];

      if (currentIndex === -1) newItems.push(key);
      else newItems.splice(currentIndex, 1);
      return newItems;
    });
  }

  const clearCheckBox = () => setCheckedItems([]);


  //searchBar handler 
  const handleSearch = useCallback((value) => {
    setPageNumber(0);
    setSearchWord(value);
  },[])

  //tab switch handler
  const handleTabSwitch = (event, value) => {
    setTabSelected(value);
    setCheckedItems([]);
    setPageNumber(0);
  }


  //page handlers
  const handlePageChange = (event, page) => {
    setPageNumber(page);
    window.scrollTo(0, document.body.scrollHeight);
  }

  const handleItemsPerPageChange = event => setItemsPerPage(event.target.value);

  const searchbarOptions = {
    currentValue : searchSource,
    handler : setSearchSource,
    choices : availableRSSSources.map(name => ({name : name, value : name}))
  }



//Rendering
let search = searchByKey("title");

const fullList = useMemo(() => search(showLists[tabSelected].contents, searchWord), [search, showLists, tabSelected, searchWord]);

const totalItems = fullList.length;

//try to paginate this as this is a calculation intensive step
//listToRender = paginate(listToRender, pageNumber, itemsPerPage);
const listToRender = useMemo(() => paginate(fullList, pageNumber, itemsPerPage),[fullList,pageNumber,itemsPerPage,]);

/**
 * Displays the data taking into account pagination and searchFiltering
 */
const PageContents = (
    <React.Fragment>
      <List disablePadding>
        {listToRender.map((show, i) => (
          <Show
            key={i}
            date={show.date}
            name={show.title}
            click={bindHandleCheckbox(show.title)}
            checked={checkedItems.indexOf(show.title) !== -1}
            location={showLists[tabSelected].viewName}
          />
        ))}
      </List>
      <TablePagination
        component="div"
        rowsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
        labelRowsPerPage="View"
        count={totalItems}
        page={pageNumber}
        rowsPerPage={itemsPerPage}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleItemsPerPageChange}
      />
    </React.Fragment>
);

  //displays the TabSwitcher
  const ViewTabs = (
    <Fade in={checkedItems.length === 0}>
        <Tabs
          centered
          value = {tabSelected}
          onChange = {handleTabSwitch}
          indicatorColor = "primary"
          textColor = "primary"
          variant = "fullWidth"
          scrollButtons="off"
        >
          {showLists.map((t, index) => <Tab key={index} label={capitalize(t.viewName)}/>)}
        </Tabs>
    </Fade>
  );

  return (
    <React.Fragment>
      <Paper className={classes.round}>
        <AppBar position="static" color="default" elevation={1} className={classes.roundTop}>
          <Toolbar>
            <Grid container spacing={1} className={classes.actionBar} direction="column">
              <Grid item xs={12} >
                <Searchbar
                  searchKey={"name"}
                  searchMethod={handleSearch}
                  searchOptions={showLists[tabSelected].viewName === "online"? searchbarOptions: null }
                />
              </Grid>
                {/* Tabs */}
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs>
                  {ViewTabs}
                </Grid>
              </Grid>

            </Grid>
          </Toolbar>
        </AppBar>
        {PageContents}
      </Paper>
      <ActionBar view={showLists[tabSelected].viewName} checked={checkedItems} clearSelected={clearCheckBox}/>
      <ShowInfoViewer/>
    </React.Fragment>
  );
}
