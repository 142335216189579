import React from "react";

const vertCent = (props) => {
  const style = {
    width : "100%",
    height : "100%",
    display : "flex",
    flexDirection : "column",
    justifyContent : "center",
  }
  return (
    <div style={style}>
      {props.children}
    </div>
  )
}
export default vertCent; 
