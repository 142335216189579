/**
 * No modifications should be made to this file, this is simply a json loader and abstraction
 * from the true config env variables in the env file.
 * */
export const {
  REACT_APP_SERVER_NAME: SERVER_NAME = "Media Manager Plus",
  REACT_APP_LOGIN_BANNER: LOGIN_MESSAGE = "Welcome",
  REACT_APP_LOGIN_BACKGROUND_COLOR: LOGIN_BACKGROUND_COLOR = "#17FD00",
} = process.env;

//These are not exported but used to compose theme
const {
  REACT_APP_THEME_PRIMARY_COLOR: APP_THEME_PRIMARY = "#17FD00",
  REACT_APP_THEME_SECONDARY_COLOR: APP_THEME_SECONDARY = "#FFC300",
} = process.env;

export const APP_PALETTE = {
  primary: { 500: APP_THEME_PRIMARY },
  secondary: {
    main: APP_THEME_SECONDARY,
  },
};
