import React, {useState, Fragment} from "react";

import {useDispatch} from "react-redux"; 

import {
    Menu,
    MenuItem,
    IconButton,
} from "@material-ui/core";

import {cancelDownload, pauseDownload, resumeDownload, setActionConfirm} from "../../store/actions/index";


import {MoreVert as VertDotsIcon} from "@material-ui/icons";

export default props => {
    const {isPaused, id} = props.downloadInfo; 
    const dispatch = useDispatch();

    const [location, setLocation] = useState(null);

    //cliking the menu open
    const handleMenuOpen = (e) => setLocation(e.currentTarget);
    //closing the menu
    const handleMenuClose = () => setLocation(null);



    const cancelDownloadInfo = {
        name : "Cancel",
        message : "Are you sure you want to cancel?",
        action : () => dispatch(cancelDownload(id)),

    }
      
    const confirmToCancel = ({
        name : "Cancel Download",
        exec : () => {
            dispatch(setActionConfirm(
                cancelDownloadInfo.name,
                cancelDownloadInfo.message,
                cancelDownloadInfo.action
            ));
        }
    });

    const resume = ({
        name : "Resume",
        exec : () => {
            dispatch(resumeDownload(id));
            handleMenuClose();
        }
    });

    const pause = ({
        name : "Pause",
        exec : () => {
            dispatch(pauseDownload(id));
            handleMenuClose();
        }
    })
    const menuActions = isPaused? [resume, confirmToCancel] : [pause, confirmToCancel];


    
    return(
        <Fragment>
            <IconButton onClick={handleMenuOpen}>
                <VertDotsIcon />
            </IconButton>
            <Menu
                anchorEl={location}
                open={Boolean(location)}
                onClose={handleMenuClose} 
            >
                {menuActions.map( (action, i) => (
                    <MenuItem key={i} onClick={action.exec}>
                        {action.name}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
       
    );
}