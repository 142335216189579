import React, {useEffect} from 'react';

//importing from our components
//Person in this case is just a var we make on this scope tied to whatever was exported on the other side
import Notes from "../containers/Notes/Notes";
import ShowViewer from "../containers/ShowViewer/ShowViewer.js"
import DownloadViewer from "../containers/DownloadViewer/DownloadViewer.js"
import EpisodeDownloadMenu from "../Components/DownloadMenu/DownloadMenu";
import ActionConfirm from "../Components/ActionConfirm/ActionConfirm";
import Layout from "../Components/Layout/Layout.js"
import {Redirect} from "react-router-dom"
import {useSelector, useDispatch} from "react-redux";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  updateDownloadStatus,
  pollSystemMonitor,
  pingNC,
  updateNC,
  fetchShows,
  fetchPreferences,
  pauseAllDownloads,
  resumeAllDownloads,
  cancelAllDownloads,
  setActionConfirm,
} from "../store/actions/index.js"

import {useInterval} from "../hooks/hooks";

//this is the object you wish to render to the DOM
//
import {
  Tv,
  ImportExport,
  NoteAdd,
  CloudDownload,
  Pause as PauseIcon,
  PlayArrow as ResumeIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";

import Center from "../Components/Spacing/Center";
const REFRESH_INTERVAL_SECONDS = 1;



export default props => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);


  //fires all the get requests to update the server with perodic information
  const update = () => {
    //you need this incase the user logs out, since the setInterval is fired
    if (!isAuthenticated) return;
    dispatch(updateDownloadStatus());
    dispatch(pollSystemMonitor());
  };
  const ping = () => {
    if (!isAuthenticated) return;
    dispatch(pingNC());
  }
  
  useInterval(ping, 1000 * 10 );
  useInterval(update, 1000 * REFRESH_INTERVAL_SECONDS);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchPreferences());
      dispatch(updateNC());
    }
  }, [dispatch, isAuthenticated]);

  const appViews = [
    {
      name: "Shows",
      el : () => (<Center padding={["top", "right" , "left"]}><ShowViewer/></Center>),
      icon: <Tv/>
    },
    {
      name: "Downloads" ,
      el : () => (<Center padding={["top", "right" , "left"]}><DownloadViewer/></Center>),
      icon : <ImportExport/>},
    {
      name: "Notes",
      el : () => (<Center padding={["top", "right", "left"]}><Notes/></Center>),
      icon : <NoteAdd/>},
  ];

  const confirmCancelDownloadsInfo = ({
    title : "Cancel All Downloads",
    message : "Are you sure you want to cancel all downloads?",
    action : () => dispatch(cancelAllDownloads()),
  })
  
  const appActions = [
    {
      name : "Fetch Shows" ,
      procedure : () => dispatch(fetchShows()),
      icon :<CloudDownload/>,
    },
    {
      name : "Pause Downloads",
      procedure : () => dispatch(pauseAllDownloads()),
      icon : <PauseIcon/>
    },
    {
      name : "Resume Downloads",
      procedure : () => dispatch(resumeAllDownloads()),
      icon : <ResumeIcon/>
    },
    {
      name : "Cancel Downloads",
      procedure : () => dispatch(setActionConfirm(
        confirmCancelDownloadsInfo.title,
        confirmCancelDownloadsInfo.message,
        confirmCancelDownloadsInfo.action
      )),
      icon : <CancelIcon/>
    },
  ];
  //this is placed at the end as all the hooks must be guarenteed to run
  if (!isAuthenticated) return <Redirect to="/login"/>
  return (
    <div className="App" style={{}}>
      <CssBaseline/>
      <Layout views={appViews} actions={appActions}/>
      <EpisodeDownloadMenu/>
      <ActionConfirm/>
    </div>
  );
}
