import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {
  Typography,
  LinearProgress,
  Grid,
} from "@material-ui/core/";


const useStyles = makeStyles(theme => ({
  root: {
    width : "100%",
    padding: theme.spacing(1),
  },
}));


export default props => {
  const {ram = {used : 0, total : 0}} = props;
  const classes = useStyles();

  const percentage = Math.round((ram.used/ram.total)*100);

  return (
    <Grid container direction="column" spacing={1} className={classes.root}>
      <Grid item container>
        <Grid item xs={10}>
          <Typography variant="h6">
            RAM Usage
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right">
            {`${percentage}%`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <LinearProgress
          variant="determinate"
          value={percentage}
        />
      </Grid>
    </Grid>
  );
}
