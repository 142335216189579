import * as api from "./api";
import * as actionTypes from "./actionTypes";

import axios from "axios";
import {actionError} from "./utils";

export const getEpisodeList = showName => async dispatch => {

  try {
    const route = `${api.getEpisodeList}/${showName}`
    //redirects will be resolved 
    const response = await axios.get(route, {validateStatus : (status) => status < 400});
    if (response.status === 310) {
      dispatch(downloadFile(showName, ""));
    }
    else {
      const episodes = response.data.files;
      dispatch(saveEpisodeList(showName, episodes)); 
    }
  } catch (err) {
    actionError(dispatch, err, "Could not retrieve list of episodes", {logError: true});
  }
}

export const downloadFile = (showName, fileName) => dispatch => {
    const route = `${api.downloadFile}/${showName}/${fileName}`;
    //creates an anchor element, clicks it and removes it
    //simplist way to prompt a file download in browser
    const link = document.createElement('a');
    link.href = route;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const saveEpisodeList = (showName, fileList) => {
  return ({
    type : actionTypes.UPDATE_DOWNLOAD_MENU,
    fileList : fileList,
    showName : showName,
  });
}

export const clearEpisodeList = () => {
  return ({
    type : actionTypes.UPDATE_DOWNLOAD_MENU,
    fileList : [],
    showName : null,
  })
}
