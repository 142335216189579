import * as actionTypes from "../actions/actionTypes";

const initialState = {
  notes: [],
}

//action = {
//  notes : [
//  ...
//  ]}
const updateNotes = ( state, action ) => {
  return {...state, notes: action.notes}
}


const reducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.UPDATE_NOTES:
      return updateNotes(state, action);
    default :
      return state;
  }
}

export default reducer;
