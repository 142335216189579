export {join} from "path"; 

export const getInfo = "/api/info";
export const trackShow = "/api/track";
export const deleteShow = "/api/show";
export const downloadProgress = "/api/torrents";
export const testSession = "/api/user";
export const notes = "/api/notes";
export const upload = "/uploads";

//for authentication
export const signin = "/api/signin";
export const signout = "/api/signout";
export const signup = "/api/signup";


//media ingest
export const mediaIngest = "/api/media/ingest"
export const mediaFetch = "/api/media/fetch"

//downloading shows from server to client
export const getEpisodeList = "/api/videos"
export const downloadFile = "/videos"


//for user preferenes
export const preferences = "/api/preferences"

//For user backlogs
export const backlog = "/api/backlog";

//For showLists
export const showLists = "/api/shows";

//For System Monitor
export const systemMonitor = "/api/info"; 

//For Notifications
export const notificationCenter  = "/api/user/notifications";

//used for pausing, cancelling and resumeing downloads
export const activeDownloads = "/api/torrents"
