import { useState, useEffect } from 'react';

const getDimentions = () => {
    const {innerWidth, innerHeight} = window;
    return ({width : innerWidth, height : innerHeight});
}

export default () => {
  const [dim , setDim] = useState(getDimentions());
  useEffect(() => {
    const handleResize = () => setDim(getDimentions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return dim;
}

