

import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import {
  Typography,
  Button,
  TextField,
  Link,
} from "@material-ui/core/";

import {login}  from "../../store/actions/index.js";
import {Redirect} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux"
import SingleCardLayout from "../../Components/Sizing/SingleCardLayout";
import {LOGIN_MESSAGE} from "../../config/config";

import LoginTheme from "../../Components/Themes/LoginTheme";
const useStyles = makeStyles(theme => ({
  input:{
    width : "100%",
  },
  textField : {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  buttonSection:{
    marginTop: theme.spacing(5)
  },
}));

export default props => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  
  const userChange = (event) => {
    setUsername(event.target.value);
  }

  const passChange = (event) => {
    setPassword(event.target.value);
  }

  const loginSubmit = (e) =>{
    e.preventDefault();
    dispatch(login(username, password));
  }

  const Title = (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h4">
         {LOGIN_MESSAGE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          Sign In
        </Typography>
      </Grid>
    </Grid>
  );

  const Fields = (
    <Grid item container xs={12} direction="column" justify="center" alignItems="center">
      <Grid item xs={12} className={classes.input}>
        <TextField onChange={userChange} label="username" className={classes.textField}/>
      </Grid>
      <Grid item xs={12} className={classes.input}>
        <TextField onChange={passChange} type="password" label="password" className={classes.textField}/>
      </Grid>
    </Grid>
  )

  const Buttons = (
    <Grid item container xs={12} className={classes.buttonSection}>
      <Grid item xs={6} style={{display: "flex", alignItems: "center"}}>
      <Link href="/signup" >
        Create An Account
      </Link>
      </Grid>
      <Grid item xs={6} style={{display : "flex" , justifyContent: "flex-end", alignItems: "center"}}>
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
      </Grid>
    </Grid>
  )

  //if the user has already logged in, then redirect to the main page
  if (isAuthenticated){
    return (
      <Redirect to="/"/>
    );
  }


  return (
    <LoginTheme>
        <SingleCardLayout>
          <form onSubmit={loginSubmit}>
            <Grid container direction="column" spacing={1}>
              {Title}
              {Fields}
              {Buttons}
            </Grid>
          </form>
        </SingleCardLayout>
    </LoginTheme>
  );
}
