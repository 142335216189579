import React, {useState, Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';


import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Hidden, 
} from "@material-ui/core/"

import {Menu} from "@material-ui/icons"

import NotificationCenter from "../NotificationCenter/NotificationCenter.js";
import SideBar from "./SideBar";
import {SERVER_NAME} from "../../config/config";




const useStyles = makeStyles(theme => {
  const drawerWidth = theme.spacing(35);
  const actionToolbarBottomSpacing = theme.spacing(8);

  return ({
    root: {
      display: "flex",
    },
    sideBarResponsiveContainer : {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    sideBarRoot : {
      width : drawerWidth,
      paddingBottom : theme.spacing(5),
      overflowX : "hidden"
    },
    namePlate: {
      flex: 1
    },
    //this is temp
    mainApp : {
      flexGrow : 1,
      width : "100%",
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    },
    content :  {
      backgroundColor : theme.palette.background.default,
      marginBottom : actionToolbarBottomSpacing,
    },

  });
});




export default props => {
  //state
  const [sideDrawer, setSideDrawer] = useState(false);
  const [activeView, setActiveView] = useState(0);
  //style
  const classes = useStyles();

  //handlers
  const generateHandleChangeView = index => () => {
    setActiveView(index);
    setSideDrawer(false);
  }

  const generateHandleDrawerOpen = value => () => setSideDrawer(value);


  const responsiveSidebar = (
    <Fragment>
      <Hidden mdUp>
        <SideBar
          open={sideDrawer}
          variant="temporary"
          onClose={generateHandleDrawerOpen(false)}
          nav={props.views}
          actions={props.actions}
          changeView={generateHandleChangeView}
          classes={{ paper: classes.sideBarRoot }}
        />
      </Hidden>
      <Hidden smDown>
        <SideBar
          open
          variant="permanent"
          nav={props.views}
          actions={props.actions}
          changeView={generateHandleChangeView}
          classes={{ paper: classes.sideBarRoot }}
        />
      </Hidden>
    </Fragment>
  )


  //rendering
  const View = () => {
    //NOTE this requires that the views passed are UNREDENRED
    const Element = props.views[activeView].el;
    if (!Element) return <Typography>oops</Typography>;
    return <Element/>
  }

  return (
    <div className={classes.root}>


      <div className={classes.sideBarResponsiveContainer}>
        {responsiveSidebar}
      </div>

      <div className={classes.mainApp}>
        <AppBar className={classes.topBar} position="sticky">
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                onClick={generateHandleDrawerOpen(true)}
                className={classes.menuButton}
                color="inherit"
              >
                <Menu />
              </IconButton>
            </Hidden>

            <Typography variant="h6" color="inherit" className={classes.namePlate}>
              {SERVER_NAME}
            </Typography>
            <NotificationCenter/>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
            <View className={classes.content}/>
        </div>
      </div>

    </div>
  );
}
