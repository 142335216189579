import React from "react"; 

import {SnackbarProvider} from "notistack";
import {Close as CloseIcon} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import NotistackListener from "./NotistackListener";


export default (props) => {
    const notistackRef = React.createRef();
    
    //generate a dismiss function tied to the key provided
    const dismiss = key => () => { 
        notistackRef.current.closeSnackbar(key);
    }

    const dismissAction = (key) => (
        <IconButton onClick={dismiss(key)}>
            <CloseIcon/>
        </IconButton>
    );

    return (
        <SnackbarProvider 
            ref={notistackRef}
            maxSnack={3}
            action = {dismissAction}
        >
            {props.children}
            <NotistackListener/>
        </SnackbarProvider>
    );

}