import * as actionTypes from "../actions/actionTypes";

const initialState = {
  episodes : [],
  showName : null
}

const update = (action) => ({
  episodes : [...action.fileList],
  showName : action.showName,
});




const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOWNLOAD_MENU:
      return update(action);
    default:
      return state;
  }
}

export default reducer;
