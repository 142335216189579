import React from "react";

import {Grid, Typography} from "@material-ui/core";

import {makeStyles, useTheme} from "@material-ui/core/styles"
import DataGraph from "./DataGraph"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    paddingBottom: 0
  },
}));

/*
props :{

usage: [array of size min 1]

}


*/
export default props => {
  const {usage = [0]} =  props;

  const theme = useTheme();
  const classes = useStyles();


  const data = {
    name : "cpu usage",
    color : theme.palette.primary.main,
    values : usage,
  }
  return(
    <Grid container direction="column" spacing={1} className={classes.root}>
      {/* Header Section */}
      <Grid item container>
        <Grid item xs={10}>
          <Typography variant="h6">
            CPU Usage
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="right">
            {`${usage[usage.length-1]}%`}
          </Typography>
        </Grid>
      </Grid>
      {/* Graph section */}
      <Grid item xs={12}>
        <DataGraph range={[0,100]} data={data}/>
      </Grid>
    </Grid>
  );
}
