import React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";

import {useSelector, useDispatch} from "react-redux";
import {clearActionConfirm} from "../../store/actions/index";
export default props => {
  const dispatch = useDispatch(); 
  const dialogContent = useSelector(state => state.actionConfirm); 

  const handleClose = () => dispatch(clearActionConfirm());
  
  //run the action requested and close the dialog afterwards
  const executeAction = () => {
    dialogContent.action();
    dispatch(clearActionConfirm());
  }
  return (
    <Dialog
      open={dialogContent.open}
      fullWidth
      onClose={handleClose}>
      <DialogTitle>
        {dialogContent.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogContent.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={executeAction} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

}









