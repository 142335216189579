import axios from "axios";
import { actionError } from "./utils";

import * as api from "./api";
import * as actionTypes from "./actionTypes";

export const pollSystemMonitor = () => async (dispatch) => {
  try {
    const result = await axios.get(api.systemMonitor);
    dispatch(saveData(result.data));
  } catch (err) {
    //While this does mean handler does nothing, it's good practice to send all errors to the same handler
    actionError(dispatch, err, "Could not fetch system montiro info", {
      logError: false,
      notifyUser: false,
    });
  }
};

function saveData(data) {
  try {
    return { ...data, type: actionTypes.UPDATE_SERVER_STATUS };
  } catch (err) {
    console.log(
      "An error occured while attempting to update system monitor with data"
    );
    return { type: "" };
  }
}
