import React from "react";

import {
  Paper,
  Typography,
} from "@material-ui/core";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  noteThumb : {
    width : 250,
    height : 150,
    padding : theme.spacing(1),
  },
  contentBox :{
    overflowY : "hidden",
    width : "100%",
    height: "100%",
  }
}));

export default props => {
  const classes = useStyles();
  return (
    <Paper onClick={props.onClick} className={classes.noteThumb}>
      <div className={classes.contentBox}>
        {props.content.split("\n").map((line , i ) => (<Typography key={i}>{line}</Typography>))}
      </div>
    </Paper>
  );
}
