import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import useWindowSize from "../../hooks/useWindowSize";
import Center from "../Spacing/Center";
import VerticalCenter from "../Spacing/VerticalCenter";
const useStyles = makeStyles(theme => ({

    card : {
        padding: theme.spacing(4),
        margin : theme.spacing(4),
    }
}));

export default props => {
    const classes = useStyles();
    const size = useWindowSize();
    return (
        <div style={{height: size.height}}>
            <VerticalCenter>
                <Center size="sm">
                    <Paper className={classes.card}>
                        {props.children}
                    </Paper>
                </Center>
            </VerticalCenter>
        </div>
    );
}