const APPROPROATE_PLACES = 3
const DECIMALS = 2;





const FACTORS = {
  "" : 1,
  "K": 1000,
  "M": 1000000,
  "G": 1000000000,
  "T": 1000000000000,
  "B": 1,
  "KB": 1000,
  "MB": 1000000,
  "GB": 1000000000,
  "TB": 1000000000000,
}

const ORDERED_UNITS = [
{name : "B", "value": 1},
{name : "KB", "value": 1000},
{name : "MB", "value": 1000000},
{name : "GB", "value": 1000000000},
{name : "TB", "value": 1000000000000},
]


export const convertUnits = (from, to, value) => {
  const getFactor = (unit) => FACTORS[unit.toUpperCase()];
  return value * getFactor(from) / getFactor(to);
}

export const getContextUnit = (nativeUnit, val) => {
  const cutoffRange = (10**APPROPROATE_PLACES);
  //convert to the lowest unit, then divide it by the appropriate decimals so you can directly compare with the unit
  //we explicity define a cuttoff to be easy to change after


  const convertedValue = convertUnits(nativeUnit, "B", val)/cutoffRange;
  for (var unit in ORDERED_UNITS){
    const {name, value} = ORDERED_UNITS[unit];
    if (convertedValue < value)  return name
  }
  //if we overrun the checks since the order is smallest to largest return the last one
  return ORDERED_UNITS[ORDERED_UNITS.length-1].name;

}

const format = (val, decimals)  => Math.round(val * (10**decimals)) / (10**decimals);


const convertToContext = (nativeUnit, val) => {
  const context = getContextUnit(nativeUnit, val);
  return {value : format(convertUnits(nativeUnit, context, val),DECIMALS), unit : context};
}

export const toDataContext = (nativeUnit, value) => {
  const c = convertToContext(nativeUnit, value); 
  return `${c.value}${c.unit}`
}
