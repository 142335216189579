

export const title = function(episodeName, tagStrip = false) {
    // eslint-disable-next-line
    var tagMatch = /^[\(\[].*?[\)\]]/gm;
    // eslint-disable-next-line
    var epMatch = /([\ _]-[\ _][0-9].*|_[0-9]*_.*)/g;

    var ret = episodeName.replace(epMatch, "");
    if (tagStrip) ret = ret.replace(tagMatch, "");

    return ret;
};

export const searchWordFilter = (showList, searchBasis,searchWord) => {
  if (searchWord.length < 3 ) return showList;
  //escape all special characters for regexs
  const regex = new RegExp(searchWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "i");

  return showList.filter(x => {
    return searchBasis in x && x[searchBasis].match(regex)
  });
}

export const searchByKey = (key) => (collection, searchWord) => {
  if (searchWord.length < 3) return collection;

  const regex = new RegExp(searchWord.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "i");

  return collection.filter(x => {
    return key in x && x[key].match(regex);
  });
}

export const capitalize = (string) => string.replace(/^\w/, c => c.toUpperCase());

export const calcDeltaTime = (date) => {
  const ORDERED_TIME = [
    {name : "second" , value : 1},
    {name : "minute" , value : 60},
    {name : "hour" , value : 3600},
    {name : "day" , value : 86400},
  ]
  const format = (val, decimals)  => Math.round(val * (10**decimals)) / (10**decimals);
  const getContextUnit = (value) => {
    for (var i = 0 ; i < ORDERED_TIME.length - 1;  i++){
      if (delta < ORDERED_TIME[i + 1 ].value) return ORDERED_TIME[i];
    }
    return ORDERED_TIME[ORDERED_TIME.length - 1 ];
  }
  //time difference converted to seconds
  var delta = Math.round((new Date().getTime() - date.getTime()) / 1000);
  var unit = getContextUnit(delta);
  delta = format(delta/unit.value, 0);
  var timeUnit = delta === 1 ? unit.name : `${unit.name}s`
  return `${delta} ${timeUnit} ago`
}



export const isCurrent = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() &&
  date.getMonth() === today.getMonth() &&
  date.getFullYear() === today.getFullYear();
  }

export const isWithin24H = (date) => {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return date.getTime() > yesterday.getTime();
}
