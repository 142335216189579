import * as actionTypes from "../actions/actionTypes.js";
const initialState = {
  status: {
    cpu: [0],
    network: {
      unit: "B",
      download: [0],
      upload: [0],
    },
    ram: {
      total: 100,
      unit: "B",
      used: 0,
    },
    temp: [0],
    time: "00:00:00",
    storage: [
      {
        name: "/Host_Drives",
        total: 905623229890,
        unit: "B",
        used: 452811614945,
      },
      {
        name: "/Host_Drives/dsk_main",
        total: 905623229890,
        unit: "B",
        used: 603748819927,
      },
    ],
  },
  downloads: [],
};

const updateServerStatus = (state, action) => {
  const createMemoryStatus = (memory) => {
    return memory;
  };

  const createStorageStatus = (drives) => {
    return [
      ...drives.map((drive) => {
        return {
          name: drive.mountPoint,
          unit: drive.unit,
          used: drive.used,
          total: drive.size,
        };
      }),
    ];
  };

  const createCPUStatus = (cpuData) => {
    //buffer is now handled directly by server side now
    return cpuData;
  };

  const createTempStatus = (tempData) => {
    //only need the cpu temp for now
    return tempData.map((dataPoint) => dataPoint.cpu);
  };

  const createNetworkStatus = (networkData) => {
    const uploadHistory = networkData.map((e) => e.upload);
    const downloadHistory = networkData.map((e) => e.download);
    return {
      unit: networkData.unit,
      upload: uploadHistory,
      download: downloadHistory,
    };
  };

  var newStatus = {
    cpu: createCPUStatus(action.cpu),
    temp: createTempStatus(action.temps),
    time: action.uptime,
    ram: createMemoryStatus(action.memory),
    storage: createStorageStatus(action.storage),
    network: createNetworkStatus(action.network),
  };

  return {
    ...state,
    status: newStatus,
  };
};

const updateDownloadStatus = (state, action) => {
  return {
    ...state,
    downloads: action.downloads,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERVER_STATUS:
      return updateServerStatus(state, action);
    case actionTypes.UPDATE_DOWNLOAD_STATUS:
      return updateDownloadStatus(state, action);
    default:
      return state;
  }
};

export default reducer;
