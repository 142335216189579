import React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  ResponsiveContainer
} from "recharts"


/**
 * Graphs teh data
 * @param  {[Object] || Object} props.data [description]
 * @param  {String} props.data[].color The color of the graph
 * @param  {[Number]} props.data[].values the array of values
 * @param {String} props.data[].name the name of the data set
 */
export default (props) => {

  let data = Array.isArray(props.data)? props.data : [props.data];

  let graphSet = [];
  //this traverses only each dataSeries for multiple graphs
  data.forEach((dataSeries) => {
    dataSeries.values.forEach((value, i) => {
      //if the point was never created, create it
      if (typeof graphSet[i] !== "object") graphSet[i] = {};
      graphSet[i][dataSeries.name] = value;
    });
  });

  const {range = [0 ,"auto"]} = props;

  const plots = data.map((series, i ) => (
    <Area
      key={i}
      isAnimationActive={false}
      type="monotone"
      dataKey={series.name}
      stroke={series.color}
      fillOpacity={0.5}
      fill={series.color}
    />
  ));

  return (
  <ResponsiveContainer width="100%" height={120}>
    <AreaChart data={graphSet}>
      <XAxis height={8} tick={false}/>
      <YAxis width={8} tick={false} domain={range}/>
      {plots}
    </AreaChart>
  </ResponsiveContainer>
  )
}
