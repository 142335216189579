import {combineReducers} from "redux"

import auth from "./auth.js";
import shows from "./shows.js";
import status from "./status.js";
import notification from "./notification.js";
import serverMessages from "./serverMessages.js";
import notes from "./notes.js";
import episodeDownloader from "./episodeDownloader.js";
import settings from "./settings.js"; 
import RSS from "./RSSSources.js"; 
import actionConfirm from "./actionConfirm";
import ingest from "./ingest";


export default combineReducers(
  {
    auth,
    shows,
    status,
    notification,
    serverMessages,
    notes,
    episodeDownloader,
    settings,
    RSS,
    actionConfirm,
    ingest,
  }
);
