import React, {useEffect} from "react"; 

import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";

export default props => {
    const notification = useSelector(state => state.notification); 

    const {enqueueSnackbar} = useSnackbar();

    //only enqueues notistack if the id changes and is not the first one
    useEffect(() => {
        //valid notification
        if (notification.id > -1){
            enqueueSnackbar(notification.message, {variant : notification.variant});
        }
    },[enqueueSnackbar, notification])

    return (
        <span></span>
    );
    
}