import axios from "axios";
import path from "path"; 

import * as api from "./api";
import * as actionTypes from "./actionTypes"

import {title} from "../../utils/util";
import {actionOK, actionError} from "./utils";

//methods for updating showlists

export const fetchSources = () => async dispatch => {
  try {
    const route = api.join(api.showLists, "sources");
    const response = await axios.get(route);
    const sources = response.data.sources; 
    dispatch(saveRSSSources(sources)); 
  } catch (err) {
    actionError(dispatch, err, "Could not fetch available RSS Sources",);
  }
}

export const fetchOnlineList = (source = "") => async dispatch => {
  if (source === "") return; 

  try {
    const route = path.join(api.showLists, source);
    const res = await axios.get(route);
    const shows = res.data.shows; 
    dispatch(saveOnlineList(shows));
  } catch (err) {
    actionError(dispatch, err, `Could not fetch shows from ${source}`);
  }
}

export const fetchLibList = () => async dispatch => {

  try {
    const route = api.join(api.showLists, "library");
    const res = await axios.get(route); 
    const shows = res.data.shows;
    dispatch(saveLibraryList(shows)); 
  } catch (err) {
    actionError(dispatch, err, "Could not fetch shows from library");
  }
}

export const fetchUserList = () => async dispatch => {

  try {
    const route = api.join(api.showLists, "user");
    const response = await axios.get(route);
    const shows = response.data.shows;
    dispatch(saveUserList(shows)); 
  } catch (err) {
    actionError(dispatch, err, "Could not fetch shows in user's watchlist"); 
  }
}

export const fetchBacklogList = () => async dispatch =>{
  try {
    const response = await axios.get(api.backlog);
    const shows = response.data.shows; 
    dispatch(saveBacklog(shows)); 
  } catch (err) {
    actionError(dispatch, err, "Could not fetch shows in user's backlog");     
  }
}

//methods for showactions
export const trackShow = (showList, source) => async dispatch => {
  try {
    const data = {shows : showList, source : source};
    await axios.put(api.trackShow, data);
    actionOK(dispatch, "Added to watchlist"); 
    dispatch(fetchUserList());
  } catch (err) {
    actionError(dispatch, err, "Could not add show to watchlist", {logError: true}); 
  }
}
export const deleteShow = showlist => async dispatch => {
  const data = {shows: showlist};
  try {
    await axios.delete(api.deleteShow, {data: data});
    actionOK(dispatch, "Removed from watchlist"); 
    dispatch(fetchUserList())
  } catch (err) {
    actionError(dispatch, err, "Could not remove show from watchlist");
  }
}






//this does not use redux
export const info = (name) => {
  const SEARCH = "https://www.google.com/search?q="

  window.open(SEARCH + title(name, true))
}

//for display more server information about the show
export const serverInfo = (name) =>  {
  return {type : actionTypes.DISPLAY_SERVER_INFO, showName : name};
}
export const clearServerInfo = () => {
  return {type : actionTypes.CLEAR_SERVER_INFO}; 
}

export const saveOnlineList = (shows) => {
  //TODO parse data from the response and turn it to just a text array
  //shows = shows.map((showName) => ({name: showName}));
  return {type: actionTypes.UPDATE_ONLINE_LIST, list: shows};
}

export const saveLibraryList = (shows) => {
  //shows = shows.map((showName) => ({name: showName}));
  return {type: actionTypes.UPDATE_LIBRARY_LIST, list: shows};
}

export const saveUserList = (shows) => {
  //shows = shows.map((showName) => ({name: showName}));
  return {type: actionTypes.UPDATE_USER_LIST, list: shows};
}

export const saveBacklog = (shows) => {
  return {type : actionTypes.UPDATE_BACKLOG_LIST, list : shows};
}

export const saveRSSSources = (sources) => {
  return {type : actionTypes.UPDATE_RSS_SOURCES, sources : sources};
}