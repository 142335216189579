export const UPDATE_SERVER_STATUS = "UPDATE_SERVER_STATUS";
export const UPDATE_DOWNLOAD_STATUS = "UPDATE_DOWNLOAD_STATUS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const UPDATE_ONLINE_LIST = "UPDATE_ONLINE_LIST";
export const UPDATE_LIBRARY_LIST = "UPDATE_LIBRARY_LIST";
export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export const UPDATE_BACKLOG_LIST = "UPDATE_BACKLOG_LIST"; 
export const UPDATE_RSS_SOURCES = "UPDATE_RSS_SOURCES";
export const UPDATE_INGEST_LOCATIONS = "UPDATE_INGEST_LOCATIONS";

export const RECIEVE_MESSAGES = "NEW_SERVER_MESSAGE_RECIEVE";
export const NOTIFICATION = "NEW_NOTIFICATION";


export const UPDATE_NOTES = "NEW_NOTES";


export const UPDATE_DOWNLOAD_MENU = "UPDATE_DOWNLOADS_MENU";

export const SET_DARK_THEME = "SET_DARK_THEME";

export const DISPLAY_SERVER_INFO = "DISPLAY_SERVER_INFO";
export const CLEAR_SERVER_INFO = "CLEAR_SERVER_INFO";  


//actions to manage global actionconfirm dialog
export const SET_ACTION_TO_CONFIRM = "SET_ACTION_CONFIRM";
export const CLEAR_ACTION_TO_CONFIRM = "CLEAR_ACTION_CONFIRM";


